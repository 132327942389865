import { tv, type VariantProps } from "../../../util/tv";

export const textVariant = tv({
  slots: {
    base: [],
  },
  variants: {
    theme: {
      dark: {
        base: "text-white",
      },
      light: {
        base: "text-black",
      },
    },
    size: {
      small: {
        base: "text-display-12",
      },
      tiny: {
        base: "text-display-12 tracking-widest",
      },
      tinyLabel: {
        base: "text-display-14 tracking-widest",
      },
      label: {
        base: "text-display-14 md:text-display-16 leading-tight",
      },
      body: {
        base: "text-display-16 leading-relaxed",
      },
      tinyBody: {
        base: "text-display-14",
      },
      subtitle: {
        base: "text-display-18 lg:text-display-20 leading-snug",
      },
      description: {
        base: "text-display-16 lg:text-display-18 leading-snug",
      },
      bigger: {
        base: "text-display-60 sm:text-display-72 md:text-display-80 lg:text-display-96 leading-none",
      },
      h1: {
        base: "text-display-40 lg:text-display-48 xl:text-display-64 leading-none",
      },
      h2: {
        base: "text-display-40 lg:text-display-48 xl:text-display-52 leading-none",
      },
      h3: {
        base: "text-display-32 lg:text-display-40 xl:text-display-48 leading-snug",
      },
      h4: {
        base: "text-display-28 lg:text-display-32 xl:text-display-40 leading-snug",
      },
      h5: {
        base: "text-display-24 lg:text-display-28 xl:text-display-32 leading-snug",
      },
      h6: {
        base: "text-display-20 lg:text-display-24 leading-snug",
      },
      tag: {
        base: "text-display-16 tracking-wider",
      },
      cigarbar: {
        base: "text-display-14 lg:text-display-16 leading-snug",
      },
    },
    uppercase: {
      true: "uppercase",
    },
    italic: {
      true: "italic",
    },
    weight: {
      light: "font-light",
      normal: "font-normal",
      medium: "font-medium",
      semibold: "font-semibold",
      bold: "font-bold",
    },
  },
  defaultVariants: {
    uppercase: false,
    italic: false,
  },
});

export type TextVariantProps = VariantProps<typeof textVariant>;

export type TextReturnVariantProps = ReturnType<typeof textVariant>;
